<template>
  <div>
    <nav-bar headerTitle="团课合照" ref="head" :show-back="true" :header-back="newAppBack" />
    <div class="container" :style="`padding-top: ${stickyHeight + 16}px;`" v-if="userId">
      <van-list v-model="loading" :finished="finished" @load="getList">
        <van-index-bar :sticky-offset-top="stickyHeight" :index-list="['', '']">
          <template v-for="(value,i) in records">
            <van-index-anchor :key="i">{{ value.date }}</van-index-anchor>
            <div class="list-box row-between-start flex-wrap" :key="i">
              <img v-for="(item) in value.photoList" :key="item.photoUrl" :src="item.photoUrl" class="record-item"  @click="goPhoto(item)" />
            </div>
          </template>
        </van-index-bar>
      </van-list>
      <div v-if="records.length === 0" class="empty f26 col-center-center">
        <img src="https://img.chaolu.com.cn/ACT/teamWarband-202308/empty.png" alt="" />
        <p>暂无团课合照</p>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar/nav-bar'
import { newAppBack, initBack, appOpenWeb } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'

export default {
  mixins: [userMixin],
  components: {
    navBar,
  },
  data() {
    return {
      stickyHeight: 0,
      records: [],
      pageNum: 1,

      loading: false,
      finished: false,
    }
  },
  computed: {},
  async created() {
    // 初始化
    await this.$getAllInfo()
    initBack()
  },
  mounted() {
    setTimeout(() => {
      this.stickyHeight = this.$refs.head.$el.offsetHeight
      console.log(this.stickyHeight);
    }, 300);

  },

  methods: {
    newAppBack,

    getList() {
      this.$axios
        .post(this.baseURLApp + '/user/course/schedule/photo/page', {
          current: this.pageNum,
          size: 10,
          userId: this.userId,
          token: this.token,
        })
        .then((res) => {
          const r = res.data.records
          let photoLen = 0
          r.map((item) => {
            photoLen += item.photoList.length
          })

          if (photoLen < 10) {
            this.finished = true
          }

          let rlen = this.records.length
          if (rlen && this.records[rlen - 1].date === r[0].date) {
            this.records[rlen - 1].photoList = this.records[rlen - 1].photoList.concat(r[0].photoList)
            r.splice(0, 1)
          }
          this.records = this.records.concat(r)

          this.pageNum++
          this.loading = false
        })
    },
    goPhoto(item) {
      appOpenWeb('', `${window.location.origin}/#/group-photo/photo?userCourseId=${item.userCourseId}&schedulePhotoId=${item.schedulePhotoId}`)
    },
  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  * {
    box-sizing: border-box;
  }
  width: 100%;
  box-sizing: border-box;
  background-color: #F5F5F5;
  min-height: 100vh;
  box-sizing: border-box;
  padding: calc(32px + env(safe-area-inset-top)) 24px calc(100px + env(safe-area-inset-top));
  .empty {
    height: 660px;
    color: #6c727a;
    img {
      width: 320px;
      height: 320px;
      margin-bottom: 20px;
    }
  }
  .list-box {
    width: 100%
  }
  .record-item {
    width: 340px;
    height: 340px;
    border-radius: 4px;
    margin: 0 0 20px;
    object-fit: cover;
  }
  /deep/.van-index-anchor {
    margin: 0 0 8px;
    padding: 0;
    font-size: 28px;
    font-weight: 600;
    background-color: #f5f5f5;
    color: #000;
  }
  /deep/.van-index-anchor--sticky {
    background-color: #f5f5f5;
  }
}
</style>
