<template>
  <div class="container">
    <nav-bar header-title="团课合照详情" :show-back="true" :header-back="newAppBack" ref="head" />

    <!-- 合照 -->
    <div class="photo-box common-box">
      <div class="row-between-center">
        <p class="f28 fw6">课程合照</p>
        <img v-if="appTypeStr !== 'mini'" @click="savePosterImg" src="https://img.chaolu.com.cn/ACT/group-photo/download-2x.png" alt="" class="icon" />
      </div>
      <img @click="previewShow = true" :src="detail.photoUrl" alt="" class="photo" />

      <!-- 点赞 -->
      <div class="like-box row-between-center">
        <div v-if="detail.userLikeNum" class="row-start-center">
          <div class="avator-list row-start-center">
            <img v-for="item in detail.userLikeList" :key="item.userId" :src="item.headImg" alt="" class="avator" />
          </div>
          <p class="p1 f24">{{ detail.userLikeNum > 5 ? `等${detail.userLikeNum}人觉得很赞` : `${detail.userLikeNum}人觉得很赞` }}</p>
        </div>
        <p v-else class="p1 f24">给教练点个赞吧~</p>
        <img @click="like" :src="`https://img.chaolu.com.cn/ACT/group-photo/${detail.likeStatus ? 'liked' : 'like'}.png`" alt="" class="icon" />
      </div>
    </div>


    <div class="course-box common-box">
      <!-- 教练说 -->
      <div class="say-box">
        <div class="row-start-center">
          <img :src="detail.teacherHeadImg" alt="" class="avator" />
          <p class="name f28 fw6">{{ detail.teacherName }}教练 · 寄语</p>
        </div>
        <p v-if="detail.teacherMessage" class="say f26">{{ detail.teacherMessage }}</p>
      </div>
      <div v-if="courseDetail.courseMainimage" class="inner">
        <img class="bg" :src="courseDetail.courseMainimage" alt="" />
        <div class="mask col-between-start">
          <div>
            <p class="f36 fw6">{{ courseDetail.courseName }}</p>
            <p class="p2 f28">{{ courseDetail.dateStr }}</p>
          </div>
          <p class="f24">{{ courseDetail.venueName }}&nbsp;&nbsp;{{ courseDetail.roomName }}&nbsp;&nbsp;{{ courseDetail.teacherName }}</p>
        </div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="buttons row-between-center f32">
      <div @click="goAppoint" class="button plain row-center-center"><img src="https://img.chaolu.com.cn/ACT/group-photo/book-2x.png" alt="" class="icon" />再次约课</div>
      <div @click="isShareShow = true" class="button fw6 row-center-center"><img src="https://img.chaolu.com.cn/ACT/group-photo/photo-2x.png" alt="" class="icon" />生成分享海报</div>
    </div>
    <!-- 海报 组件 -->
    <common-share :value="isShareShow" :shareParams="shareParams" @close="isShareShow = false" @share-success="isShareShow = false" :shareItem="['posterfriend', 'postermessage', 'save']" :miniSharePoster="appTypeStr === 'mini'">
      <template slot="createDom">
        <div class="create-dom">
          <div class="mask-box">
            <img :src="courseDetail.courseMainimage" alt="" class="bg" />
            <div class="mask"></div>
          </div>

          <div class="up-box">
            <p class="f36 fw6">{{ courseDetail.courseName }}</p>
            <p class="p2 f24">{{ courseDetail.dateStr }}</p>
            <p class="f24">{{ courseDetail.venueName }}{{ courseDetail.roomName }}&nbsp;&nbsp;{{ courseDetail.teacherName }}</p>
            <img :src="detail.photoUrl" alt="" class="photo" />

            <div class="bottom-box row-between-center">
              <div class="col-start-start">
                <img src="https://img.chaolu.com.cn/ACT/mini-records/logo.png" alt="" class="logo" />
                <p class="f24">扫码和我们一起锻炼吧~</p>
              </div>

              <img :src="`data:image/png;base64,${qrCode}`" class="qr" />
            </div>
          </div>
        </div>
      </template>
    </common-share>

    <van-image-preview v-model="previewShow" :showIndex="false" :images="[detail.photoUrl]">
      <template v-slot:cover>
        <div v-if="appTypeStr !== 'mini'" class="preview-icon row-center-center">
          <img @click="savePosterImg" src="https://img.chaolu.com.cn/ACT/group-photo/download-2x.png" alt="" class="icon" />
        </div>
      </template>
    </van-image-preview>

  </div>
</template>
<script>
import { newAppBack, initBack, saveCreateImg, gotoAppPage, universalPushVC } from '@/lib/appMethod'
import { getParam, compareVersion } from '@/lib/utils'
import navBar from '@/components/nav-bar-202210/index'
import commonShare from '@/components/commonShare/newShare'
import userMixin from '@/mixin/userMixin'
import wx from 'weixin-js-sdk'

const webPath = `${window.location.origin}/#/group-photo/photo`

export default {
  components: {
    navBar,
    commonShare,
  },
  mixins: [userMixin],
  async created() {
    initBack()
    await this.$getAllInfo(['userId', 'local'])

    this.params = {
      userId: this.userId,
      token: this.token,
      schedulePhotoId: getParam().schedulePhotoId,
      userCourseId: getParam().userCourseId
    }

    await this.getDetail()

    this.getCourseDetail()
    this.initQr()
    this.getQr()
  },
  data() {
    return {
      params: {},
      detail: {},
      qrCode: '',
      courseDetail: {},
      curLesson: {},

      isShareShow: false,
      userDetail: {}, //分享的用户信息
      shareParams: {
        title: '--',
        miniImage: 'https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_mini.png',
        path: '',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      previewShow: false,
    }
  },
  methods: {
    newAppBack,
    getDetail() {
      return this.$axios
        .post(`${this.baseURLApp}/user/schedule/photo/detail`, this.params)
        .then((res) => {
          const r = res.data

          // 只取前五个头像
          if (r.userLikeNum) {
            r.userLikeList.splice(5)
          }

          this.detail = r
        })
        .catch((r) => {
          setTimeout(() => {
            this.newAppBack()
          }, 2000);
        })
    },
    // 课程详情
    getCourseDetail() {
      this.$axios
        .post(`${this.baseURLApp}/scheduleLesson/getScheduleDetail`, this.params)
        .then((res) => {
          const r = res.data.course
          this.courseDetail = r
        })
    },

    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: `group_photo`,
            schedulePhotoId: getParam().schedulePhotoId,
            userCourseId: getParam().userCourseId,
            webUrl: webPath,
          }),
        })
        .then((res) => {
          this.userDetail = res.data
        })
    },
    getQr() {
      if (!this.detail.teacherId) return
      this.$axios
        .post(`${this.baseURLApp}/schedule/photo/scan/createQrCode`, {
          teacherId: this.detail.teacherId
        })
        .then((res) => {
          this.qrCode = res.data.qrCode
        })
    },
    savePosterImg() {
      saveCreateImg(this.detail.photoUrl)
    },
    like() {
      this.$axios.post(`${this.baseURLApp}/user/schedule/photo/like`, {...this.params, likeFlag: this.detail.likeStatus ? 0 : 1}).then((res) => {
        this.getDetail()
      })
    },
    async goAppoint() {
      const teacherId = this.detail.teacherId
      const checkInfo = await this.checkTeacher()

      // 是否团课老师  是否在职  是否有课 是否低版本
      if (!checkInfo.groupClass || !checkInfo.workStatus || checkInfo.waitGroupClassNum === 0 || compareVersion(this.appVersion, '4.61.11') < 0) {
        gotoAppPage(18, '') // 团课主页
        return
      }

      universalPushVC(51, teacherId, 1)
    },
    checkTeacher() {
      return this.$axios.post(`${this.baseURLApp}/schedule/teacher/teacherDetail`, {teacherId: this.detail.teacherId, userId: this.userId, token: this.token}).then((res) => {
        return res.data
      })
    }
  },
}
</script>
<style lang="less" scoped>
.container {
  min-height: calc(100vh - 70px + env(safe-area-inset-bottom));
  background-color: #f5f5f5;
  padding: 0 0 calc(190px + env(safe-area-inset-bottom));
  * {
    box-sizing: border-box;
  }
  .common-box {
    width: 686px;
    background-color: #fff;
    padding: 32px;
    border-radius: 8px;
    margin: 24px auto 0;
  }
  .course-box {
    .inner {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      margin: 24px 0 0;
      .bg {
        width: 100%;
        height: 240px;
        display: block;
        object-fit: cover;
      }
      .mask {
        padding: 24px 28px;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        .p2 {
          margin: 10px 0 0;
        }
      }
    }
  }
  .say-box {
    .avator {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin: 0 20px 0 0;
    }
    .name {
      color: #000;
    }
    .say {
      padding: 26px 34px;
      background-color: #F3F5FB;
      margin: 20px 0 0;
      position: relative;
      border-radius: 14px;
      &:before {
        content: '';
        position: absolute;
        bottom: 99.9%;
        left: 22px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 10px solid #f4f4f4;
      }
    }
  }
  .photo-box {
    .photo {
      display: block;
      width: 100%;
      max-height: 622px;
      margin: 20px 0 0;
      border-radius: 8px;
      object-fit: cover;
    }
    .icon {
      width: 34px;
    }
  }
  .like-box {
    margin: 30px 0 0;
    .avator-list {
      margin: 0 16px 0 0;
      .avator {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        position: relative;
        &:not(:first-child) {
          margin: 0 0 0 -16px;
        }
      }
    }
    .p1 {
      line-height: 54px;
    }
    .icon {
      width: 36px;
    }
  }
  .buttons {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 30px 30px calc(30px + env(safe-area-inset-bottom));
    background-color: #fff;
    .button {
      width: 382px;
      height: 92px;
      background: #FFDE00;
      border-radius: 8px;
      &.plain {
        width: 272px;
        background: #242831;
        color: #fff;
      }
      .icon {
        height: 36px;
        margin: 0 13px 0 0;
      }
    }
  }
  .create-dom {
    position: fixed;
    bottom: -200%;
    // bottom: 0;
    width: 686px;
    margin: 0 auto;
    background-color: #212225;
    color: #fff;
    border-radius: 12px;
    overflow: hidden;
    .up-box {
      width: 100%;
      padding: 42px 28px 50px;
      .photo {
        width: 100%;
        margin: 36px 0 0;
        display: block;
        border-radius: 12px;
      }
      .p2 {
        margin: 18px 0 12px;
      }
    }
    .mask-box {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      .bg {
        width: 100%;
        height: 290px;
        object-fit: cover;
      }
      .mask {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(33, 34, 37, 0.7) 0%, rgba(33, 34, 37, 1) 100%);
      }
    }


    .bottom-box {
      margin: 60px 0 0;
      padding: 0 8px;
      .logo {
        width: 176px;
        margin: 0 0 12px;
      }
      .qr {
        width: 96px;
      }
    }
  }

  .preview-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .3);
    position: absolute;
    bottom: calc(48px + env(safe-area-inset-bottom));
    left: 50%;
    transform: translateX(-50%);
    .icon {
      width: 40px;
    }
  }
}
/deep/.van-image-preview__cover {
    width: 100%;
    height: calc(48px + env(safe-area-inset-bottom));
    bottom: 0;
    top: unset;
  }
</style>
