<template>
  <header :class="['row-center-center flex-none', headerType === 4 ? 'app-header' : 'app-header nav-bar-bg']" ref="head" :style="headerStyle" @touchmove.prevent
    v-if="appTypeStr !== 'mini' && appTypeStr !== 'toutiao'">
    <div class="app-c-header">
      <div class="back" @click="headerBack" v-if="showBack">
        <svg viewBox="0 0 1000 1000" class="back-img">
          <path :fill="backColorCopy" fill-rule="evenodd"
            d="M296.114 508.035c-3.22-13.597.473-28.499 11.079-39.105l333.912-333.912c16.271-16.272 42.653-16.272 58.925 0s16.272 42.654 0 58.926L395.504 498.47l304.574 304.574c16.272 16.272 16.272 42.654 0 58.926s-42.654 16.272-58.926 0L307.241 528.058a41.472 41.472 0 0 1-11.127-20.023z">
          </path>
        </svg>
      </div>
      <div class="app-text-title" :style="headerTxtStyle">
        <span v-if="showTxt">{{ headerTitle }}</span>
      </div>
      <div class="account-txt" :style="rightTxtStyle" v-if="rightTxt.length > 0" @click="rightBtnClick">
        <span v-if="showTxt">{{ rightTxt }}</span>
      </div>
      <slot v-if="showTxt"></slot>
      <div class="app-share" v-if="showShare" @click="rightBtnClick">
        <svg t="1646279138239" class="share-icon" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg" p-id="2112" xmlns:xlink="http://www.w3.org/1999/xlink" width="200"
          height="200">
          <path :fill="backColorCopy" fill-rule="nonzero"
            d="M874.666667 544c-17.066667 0-32 14.933333-32 32v256c0 6.4-4.266667 10.666667-10.666667 10.666667H192c-6.4 0-10.666667-4.266667-10.666667-10.666667V192c0-6.4 4.266667-10.666667 10.666667-10.666667h256c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32H192C151.466667 117.333333 117.333333 151.466667 117.333333 192v640c0 40.533333 34.133333 74.666667 74.666667 74.666667h640c40.533333 0 74.666667-34.133333 74.666667-74.666667V576c0-17.066667-14.933333-32-32-32z"
            p-id="2113"></path>
          <path :fill="backColorCopy" fill-rule="nonzero"
            d="M874.666667 117.333333H640c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32h157.866667L509.866667 467.2c-12.8 12.8-12.8 32 0 44.8 6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333l285.866667-285.866667V384c0 17.066667 14.933333 32 32 32s32-14.933333 32-32V149.333333c0-17.066667-14.933333-32-32-32z"
            p-id="2114"></path>
        </svg>
      </div>
    </div>
  </header>
</template>
<script>
import { appType, hideAppBackBtn, appBack } from "@/lib/appMethod"
export default {
  data() {
    return {
      headerType: 4,
      showTxt: false, // 默认不显示文字
      backColorCopy: '#4C545D',

      appTypeStr: '',

      scrollDom: null,
    };
  },
  computed: {

  },
  /**
   * @params headerStyle 头部样式
   * @params headerTitle 头部标题名字
   *
   */
  props: {
    // 头部样式
    headerStyle: {
      type: Object,
      default: () => { },
    },
    // 标题文字
    headerTitle: {
      type: String,
      default: "",
    },
    // 标题样式
    headerTxtStyle: {
      type: Object,
      default: () => { },
    },
    // 返回事件
    headerBack: {
      type: Function,
      default: appBack,
    },
    // 是否显示返回
    showBack: {
      type: Boolean,
      default: true,
    },
    // 返回键颜色
    backColor: {
      type: String,
      default: "#4C545D",
    },
    // 沉浸式滚动后背景颜色
    scrollAfterBackColor: {
      type: String,
      default: "#4C545D",
    },
    // 导航右边文字
    rightTxt: {
      type: String,
      default: "",
    },
    // 是否显示导航右边默认分享图片
    showShare: {
      type: Boolean,
      default: false,
    },
    // 是否显示默认图片，沉浸式时候使用
    showBg: {
      type: Boolean,
      default: true,
    },
    // 沉浸式背景颜色
    immersiveColor: {
      type: String,
      default: "#fff",
    },
    // 导航右边文字样式
    rightTxtStyle: {
      type: Object,
      default: () => { },
    },
    // 点击右边文字按钮
    rightTxtClick: {
      type: Function,
      default: null,
    },
  },
  created() {
    try {
      hideAppBackBtn()
    } catch (e) {}
    this.headerType = this.$route.meta.headerType;
    this.backColorCopy = this.backColor;
    if (this.headerType === 4) {
      setTimeout(() => {
        if (this.showBg) this.handleScroll();
      }, 500);
    } else {
      this.showTxt = true;
    }

    this.appTypeStr = appType()
  },
  mounted() {
    this.scrollDom = $(".app-page-main").length !== 0 ? $(".app-page-main") : $(window)
    setTimeout(() => {
      console.log(this.$refs.head.clientHeight);
      this.$emit('setBarHeight', this.$refs.head.clientHeight)
    }, 1000)
  },
  destroyed() {
    $(".app-page-main").off("scroll");
  },
  methods: {
    // 滚动监听，处理沉浸式滚动
    handleScroll() {
      let dom = $(".app-header"); //header的高度
      let h = dom.height();

      this.scrollDom.scroll(() => {
        let wh = this.scrollDom.scrollTop(); //滚动的距离的，为什么这里使用的jq，因为不用考虑的什么的兼容问题
        if (wh > h) {
          let opacity = wh / (h * 2)
          opacity = opacity > 1 ? 1 : opacity
          dom.css({
            "background-color": this.immersiveColor,
            "opacity": opacity
          });
          this.showTxt = true;
          this.backColorCopy = this.scrollAfterBackColor
        } else {
          dom.css({
            "background-color": "initial",
            opacity: 1
          });
          this.showTxt = false;
          this.backColorCopy = this.backColor
        }
      });
    },
    // 点击按钮
    rightBtnClick() {
      this.$emit("right-txt-click");
    },
  },
};
</script>
<style lang="less" scoped>
@back-img-wdith: 50px;

.nav-bar-bg {
  background-color: #fff;
}

.app-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 110;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  left: 0;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  box-sizing: content-box !important;

  .app-text-title {
    .header-txt;
  }

  .back {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100px;
    height: 100%;
    align-items: center;
    justify-content: flex-start;

    .back-img {
      width: @back-img-wdith;
      height: @back-img-wdith;
      margin-left: 24px;
    }
  }

  .account-txt {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    font-size: @fontSizeTitle;
    color: #242831;
  }

  .header-txt {
    color: #242831;
    font-size: @fontSizeTitle;
    display: inline-block;
    white-space: nowrap;
    width: 74vw;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .and-txt {
    span {
      position: relative;
      // top:-0.15vw;
    }
  }
}

.app-share {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
}

.share-icon {
  width: 50px;
  height: 50px;
  display: flex;
  margin-right: 13px;
}
</style>
