<template>
  <!-- 加载进度开始 -->
  <div class="loading" v-if="loadStatus" :style="{ 'z-index': zIndex,'background-color':'rgba(59, 59, 59, '+opacity+')'}" @touchmove.prevent>
      <div class="loading-con">
          <div v-if="showLoadingImg">
              <div class="load-img">
                  <img src="./img/load.gif" />
              </div> 
              <div class="load-txt">{{loadingTxt}}</div>
          </div>
          <div v-else>
              <div v-if="hasComplete">
                <div class="load-img1">
                    <div id="container">
                        <div id="#tick-animation">
                            <img src="./img/path.png" />
                            <div class="tick"></div>
                        </div>
                    </div>	
                </div>
                <div class="load-txt">{{completeTxt}}</div>
              </div>
          </div>
      </div>
  </div>
  <!-- 加载进度结束 --> 
</template>
<script>
export default {
  data() {
    return {
      hasComplete:true //是否有加载完成后打钩动画
    };
  },
  mounted(){
    let that = this;
  },
  props: {
    // 遮罩层级
    zIndex: {
      type: Number,
      default:50
    },
    // 透明度
    opacity: {
      type: Number,
      default:0.39
    },
    // 是否显示加载
    loadStatus:{
      type: Boolean,
      default:false
    },
    // 加载是否完成(用来控制显示加载图片)
    showLoadingImg:{
      type: Boolean,
      default:true
    },
    // 加载中的文字
    loadingTxt: {
      type: String,
      default: "加载中"
    },
    // 加载完成时文字
    completeTxt:{
      type: String,
      default: "操作完成"
    }
  }
};
</script>
<style lang="less" scoped>
.loading{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .loading-con {
    width: 32vw;
    height: 32vw;
    overflow: hidden;
    background-color: #1d1b1b;
    opacity: 0.7;
    position: absolute;
    z-index: 102;
    left: 50%;
    top: 50%;
    margin-top: -16vw;
    margin-left: -16vw;
    text-align: center;
    border-radius: 3vw;
    font-size: 3.2vw;
    color: #aeaeae;
    .load-img {
      img {
        width: 7.5vw;
        margin-top: 10vw;
      }
    }
    .load-txt {
      font-size: 3.5vw;
      margin-top: 7%;
    }
    .load-img1 {
      img {
        width: 10vw;
        margin-top: 10vw;
      }
    }
  }
  //打勾动画
  #tick-animation {
    position: relative;
  }
  #container div img {
    width: 10vw;
  }
  .tick {
    position: absolute;
    width: 30vw;
    height: 18vw;
    border-radius: 3vw;
    animation: myfirst 1.5s;
    -moz-animation: myfirst 1.5s; /* Firefox */
    -webkit-animation: myfirst 1.5s; /* Safari 和 Chrome */
    -o-animation: myfirst 1.5s;
    top: 0;
    background-color: #1d1b1b;
    left: 30vw;
  }
  @keyframes myfirst {
    0% {
      left: 0;
    }

    100% {
      left: 30vw;
    }
  }

  @-webkit-keyframes myfirst {
    0% {
      left: 0;
    }

    100% {
      left: 30vw;
    }
  }
}
</style>